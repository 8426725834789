var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"auth-page@1.2.1106"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const environment = document.querySelector('meta[name="sentry-env"]')?.content ?? 'production';

Sentry.init({
  dsn: 'https://ead8b760de003701c5893df938212f92@o77844.ingest.us.sentry.io/4505753597640704',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  environment,
  beforeSend(event) {
    if (process.env.NODE_ENV === 'production') {
      return event;
    }
    return null;
  },
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - \`Cache-Control: no-transform\` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    /criteo_q/,
    /hj is not defined/,
    /MoatMAK/,
    /require is not defined/,
    /Can't find variable: mraid/,
    /Script error for/,
  ],
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_us\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /davebestdeals\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /\.kaspersky-labs\.com\//i,
    /cdnnetwok\.xyz\//i,
  ],
});
