'use client';

import Analytics, { AnalyticsInstance } from 'analytics';
import * as Sentry from '@sentry/nextjs';
import { createContext, useContext, ReactNode, useMemo } from 'react';
import {
  Collector,
  Container,
  ContainerMode,
  trackerPlugin,
  pageViewIdKey,
} from '@grupa-pracuj/analytics-plugin-gp-tracker';
import { schema as pageView } from '@grupa-pracuj/tracker-schematics/events/pl.pracuj.tracker/pageview/3-0-0';
import {
  schema as pageViewTestGroup,
  Properties as PageViewTestGroup,
} from '@grupa-pracuj/tracker-schematics/events/pl.pracuj.www/pageviewtestgroup/1-0-1';
import {
  schema as currentStepInApplicationProcess,
  Properties as CurrentStepInApplicationProcess,
} from '@grupa-pracuj/tracker-schematics/events/pl.pracuj.www/currentstepinapplicationprocess/1-0-6';
import {
  schema as currentStepInAuthProcess,
  Properties as CurrentStepInAuthProcess,
} from '@grupa-pracuj/tracker-schematics/events/pl.pracuj.www/currentstepinauthprocess/1-0-1';
import {
  schema as registrationInformation,
  Properties as RegistrationInformation,
} from '@grupa-pracuj/tracker-schematics/events/pl.pracuj.www/registrationinformation/1-0-2';
import {
  schema as webVitals,
  Properties as WebVitals,
} from '@grupa-pracuj/tracker-schematics/events/pl.pracuj.www/webvitals/1-0-0';

import { EnvironmentData } from 'src/utils/http-requests/get-environment';
import packageJson from '../../../package.json';

function sendTrackEvent<T>(analytics: AnalyticsInstance, event: string, isPageviewId = true) {
  return (payload: Omit<T, 'pageviewId'>, callback?: () => void) => {
    try {
      return analytics.track(event, {
        ...payload,
        pageviewId: isPageviewId ? analytics.storage.getItem(pageViewIdKey) : undefined,
      });
    } catch (error_) {
      Sentry.captureMessage(`Failed to send ${event} event`);
      Sentry.captureException(error_);
    } finally {
      callback?.();
    }
  };
}

function sendWebVitalsEvent(analytics: AnalyticsInstance) {
  return (payload: Omit<WebVitals, 'pageviewId'>) => {
    const { name, delta, id, value } = payload;
    const filteredPayload = { name, delta, id, value };

    sendTrackEvent<WebVitals>(analytics, 'webVitals')(filteredPayload);
  };
}

export type SendCurrentStepInAuthProcess = ReturnType<typeof sendTrackEvent<CurrentStepInAuthProcess>>;
export type SendCurrentStepInApplicationProcess = ReturnType<typeof sendTrackEvent<CurrentStepInApplicationProcess>>;
export type SendRegistrationInformation = ReturnType<typeof sendTrackEvent<RegistrationInformation>>;
export type SendWebVitals = ReturnType<typeof sendWebVitalsEvent>;
export type SendPageViewTestGroup = ReturnType<typeof sendTrackEvent<PageViewTestGroup>>;

export type AnalyticsContextProviderProps = {
  children: ReactNode;
  environment: EnvironmentData['environment'];
};

export type Analytics = {
  analytics: AnalyticsInstance;
  sendCurrentStepInAuthProcess: SendCurrentStepInAuthProcess;
  sendCurrentStepInApplicationProcess: SendCurrentStepInApplicationProcess;
  sendRegistrationInformation: SendRegistrationInformation;
  sendWebVitals: ReturnType<typeof sendWebVitalsEvent>;
  sendPageViewTestGroup: SendPageViewTestGroup;
};

const versionSuffix = '_PRACUJ3.0';
const appName = packageJson.name;
const appVersion = packageJson.version + versionSuffix;

const getAnalyticsConfig = (environment: AnalyticsContextProviderProps['environment'] = 'production') => ({
  app: appName,
  version: appVersion,
  plugins: [
    trackerPlugin({
      collector: environment === 'production' ? Collector.prod : Collector.beta,
      container: Container.pracuj,
      appName,
      appVersion,
      containerMode: ContainerMode.test,
      dangerouslyCookieAlternativeName: true,
      events: {
        webVitals,
        pageView,
        pageViewTestGroup,
        currentStepInAuthProcess,
        currentStepInApplicationProcess,
        registrationInformation,
      },
    }),
  ],
});

const AnalyticsContext = createContext<Analytics>({
  analytics: Analytics(getAnalyticsConfig()),
  sendCurrentStepInAuthProcess: () => Promise.resolve(),
  sendCurrentStepInApplicationProcess: () => Promise.resolve(),
  sendRegistrationInformation: () => Promise.resolve(),
  sendWebVitals: () => Promise.resolve(),
  sendPageViewTestGroup: () => Promise.resolve(),
});

export function AnalyticsContextProvider({ children, environment }: AnalyticsContextProviderProps) {
  const contextValue = useMemo(() => {
    const analytics = Analytics(getAnalyticsConfig(environment));

    const sendCurrentStepInAuthProcess = sendTrackEvent<CurrentStepInAuthProcess>(
      analytics,
      'currentStepInAuthProcess',
    );
    const sendCurrentStepInApplicationProcess = sendTrackEvent<CurrentStepInApplicationProcess>(
      analytics,
      'currentStepInApplicationProcess',
    );
    const sendRegistrationInformation = sendTrackEvent<RegistrationInformation>(analytics, 'registrationInformation');
    const sendWebVitals = sendWebVitalsEvent(analytics);
    const sendPageViewTestGroup = sendTrackEvent<PageViewTestGroup>(analytics, 'pageViewTestGroup');

    return {
      analytics,
      sendCurrentStepInAuthProcess,
      sendCurrentStepInApplicationProcess,
      sendRegistrationInformation,
      sendWebVitals,
      sendPageViewTestGroup,
    };
  }, []);

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
}

export default function useAnalytics(): Analytics {
  return useContext(AnalyticsContext);
}
